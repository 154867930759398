<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'
  import FetchPage from '@/mixins/fetchPage'
  const ABOUT = '4uFC5c1oZqAykczVBIKfSD'
  export const STORE_NAMESPACE_ABOUT = 'page/about'

  export default {
    name: 'About',

    metaInfo: { title: 'O firmie' },

    extends: View,

    mixins: [
      LoadSections([
        'sub-hero',
        'about-description',
        'about-description-photo',
        'about-video',
        'contact-us',
        'trusted-us',
        'footer',
      ]),
      FetchPage(ABOUT, STORE_NAMESPACE_ABOUT),
    ],

    props: {
      id: {
        type: String,
        default: 'about',
      },
    },
  }
</script>
